<template>
  <div class="flex-col page">
    <div class="flex-col group_1">
      <div class="flex-col text-wrapper">
        <span class="text_1">忘记密码</span>
      </div>
      <div class="box_1">
        <span class="text_2">请输入手机号找回密码</span>
        <div class="flex-row section_2">
          <img src="../assets/phone.png" class="image_4" />
          <input type="text" class="text_3" v-model="phone" placeholder="请输入手机号" />
        </div>
        <div class="flex-row section_3">
          <img src="../assets/dun.png" class="image_4" />
          <input type="text" class="text_4" v-model="code" placeholder="请输入验证码" />
          <div class="flex-col items-center text-wrapper_1">
            <span @click="getCode">{{count}}</span>
          </div>
        </div>
        <div :class="['flex-col', 'items-center', 'button',{button_1:isLogin}]" @click="next(isLogin)">
          <span>下一步</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {short,shorty} from '@/api/Login/Apply'
export default {
  data() {
    return {
      phone: '',
      code: '',
      show: true,
      count: '获取验证码',
      timer: null
    }
  },
  computed: {
    isLogin() {
      let boo = false
      if (this.phone && this.code) {
        boo = true
      }
      return boo
    }
  },
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    getCode() {
      short({phone:this.phone,code:this.code}).then(res=>{
          console.log(res);
          if(res.data.Result===200){
            const TIME_COUNT = 60
            if (!this.timer) {
              this.count = TIME_COUNT
              this.show = false
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--
                } else {
                  this.show = true
                  this.count = '获取验证码'
                  clearInterval(this.timer)
                    this.timer = null
                  }
              }, 1000)
            }
          }else{
            Toast.fail('请检查手机号是否正确');
          }
 
      })
    },
    next(value){
      console.log(value);
      if(value){
        sessionStorage.setItem('phone',this.phone)
        sessionStorage.setItem('code',this.code)
        this.$router.push('/pswd')
      }
    }
  }
}
</script>

<style scoped>
@import '../assets/common.css';
.image_4 {
  width: 1.5rem;
  height: 1.5rem;
}
.page {
  width: 100%;
  overflow: hidden;
}
.header {
  padding-top: 0.063rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  height: 2.81rem;
}
.group_1 {
  flex: 1 1 auto;
  overflow-y: auto;
}
.section_1 {
  padding: 0.88rem 0.81rem;
  background-color: rgb(237, 237, 237);
}
.box_1 {
  overflow-x: hidden;
  margin-top: -1.5rem;
  background-color: #f5f5f5;
  border-radius: 1.2rem 1.2rem 0 0;
  padding-top: 2.5rem;
}
.section_2,
.section_3 {
  margin: 1.56rem 1.25rem 0;
  padding: 1rem 0.88rem 0.94rem;
  color: rgb(153, 153, 153);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  background: #fff;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-wrapper_1 {
  padding: 0.56rem 0 0.5rem;
  color: rgb(255, 255, 255);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  background: #0bc7ae;
  border-radius: 0.4rem;
  width: 5rem;
  height: 1.94rem;
}
.button {
  margin: 3.69rem 1.25rem 8rem;
  padding: 1.13rem 0 1rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  background: #e2e2e2;
  background-repeat: no-repeat;
}
.button_1 {
  background-color: #005ddb;
  color: #fff;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.text-wrapper {
  padding: 5.88rem 0 6.75rem;
  color: rgb(255, 255, 255);
  font-size: 1.88rem;
  line-height: 1.75rem;
  white-space: nowrap;
  background: #0355d7 url(../assets/bg.png) right no-repeat;
}
.text_2 {
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  line-height: 1.19rem;
  white-space: nowrap;
  margin-left: 1.31rem;
}
.text_3,
.text_4 {
  flex: 1;
  margin-left: 0.5rem;
  border: 0;
  outline: none;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.text_1 {
  text-align: left;
  margin-left: 1.31rem;
}
.box_1 {
    height: 72vh;
}
</style>
